import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './style';
import * as propTypes from '../../../propTypes';

import Meta from '../../../components/Meta';
import BrandList from '../../components/BrandList';
import ButtonGroup from '../../components/ButtonGroup';
import NotFound from '../../../components/NotFound';

class BrandsPageContainer extends Component {
  static propTypes = {
    params: propTypes.params,
    brands: PropTypes.objectOf(propTypes.brands),
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const carType = this.props.params.carType || 'cars';

    if (carType !== 'cars' && carType !== 'trucks') return <NotFound />;

    const buttons = [
      { value: 'cars', label: 'Легковые', type: 'легковых', link: '/cars' },
      { value: 'trucks', label: 'Грузовые', type: 'грузовых', link: '/trucks' },
    ];

    return (
      <div className={style.wrapper}>
        <Meta page="front" />
        <div className={style.buttons}>
          <ButtonGroup values={buttons} value={carType} />
        </div>
        <BrandList brands={this.props.brands[carType]} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brands: state.brands,
});
export default connect(mapStateToProps)(BrandsPageContainer);
