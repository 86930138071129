import get from './get';

const dataLayerPush = ( event, ecommerce ) => {
  const { dataLayer } = window;
  console.log('dataLayer', event, ecommerce); //eslint-disable-line
  if (!dataLayer) return;
  dataLayer.push({ event, ecommerce });
};

const convertPart = ( part, i, list ) => {
  const item = {
    name:       get(part, ['partName', 'title'], ''), // название позиции в листинге в соответствии с внутренним каталогом (поле partname)
    id:         part.barcode || '', // Артикул товара в соответствии с внутренним каталогом (поле barcod)
    price:      part.sellingPrice, // Цена на сайте, два знака после запятой (поле siteprice)
    brand:      get(part, ['relatedBrand', 'brand', 'title'], ''), // Марка автомобиля в соответствии с внутренним каталогом (поле brand)
    dimension1: get(part, ['relatedBrand', 'model', 'title'], ''), // Модель автомобиля в соответствии с внутренним каталогом (поле model)
    dimension2: get(part, ['relatedBrand', 'generation', 'title'], ''), // Поколение автомобиля в соответствии с внутренним каталогом (поле generation)
    variant:    part.used ? 'Б/У' : 'Новый', // Тип товара (новый/бу) в соответствии с внутренним каталогом (поле used)
    category:   get(part, ['partGroup', 'title'], ''), // Группа товаров в соответствии с внутренним каталогом (поле partgroup)
    quantity:   1,
  };
  if (list) item.list = list;
  if (typeof i === 'number') item.position = i + 1; // порядковый номер позиции на странице

  return item;
};

const ITEMS_PER_CHUNK = 20;
const splitByChunks = ( array ) => {
  const chunked_arr = [];
  for (let i = 0; i < array.length; i++) {
    const last = chunked_arr[chunked_arr.length - 1];
    if (!last || last.length === ITEMS_PER_CHUNK) {
      chunked_arr.push([array[i]]);
    } else {
      last.push(array[i]);
    }
  }
  return chunked_arr;
};

const iterateOverItems = ( items, fn, convertFn = convertPart ) => {
  if (!Array.isArray(items)) return;
  const convertedItems = items.map(( item, i ) => convertFn(item, i));
  const chunks = splitByChunks(convertedItems);
  chunks.forEach(fn);
};

const getPartList = ( item ) => {
  return get(item, ['relatedBrand', 'brand', 'cargo']) ? 'Грузовые' : 'Легковые';
};

export const viewPartList = ( items, offset, list ) => {
  iterateOverItems(
    items,
    impressions => dataLayerPush('ecomList', { impressions }),
    ( item, i ) => convertPart(item, i + offset, list),
  );
};

export const viewPartSingle = ( item ) => {
  if (!item) return;
  const convertedPart = convertPart(item);
  dataLayerPush('ecomOffer', {
    detail: {
      actionField: { list: getPartList(item) },
      products:    [convertedPart],
    },
  });
};

export const clickPartFromList = ( item, index ) => {
  const convertedPart = convertPart(item, index);
  dataLayerPush('ecomClick', {
    click: {
      actionField: { list: getPartList(item) },
      products:    [convertedPart],
    },
  });
};

export const addToCart = ( item ) => {
  const convertedPart = convertPart(item);
  // convertedPart.quantity = 1;
  dataLayerPush('ecomAdd', {
    add: {
      products: [convertedPart],
    },
  });
};

export const removeFromCart = ( item ) => {
  const convertedPart = convertPart(item);
  // convertedPart.quantity = 1;
  dataLayerPush('ecomRemove', {
    remove: {
      products: [convertedPart],
    },
  });
};

export const clickCreateOrder = ( items ) => {
  iterateOverItems(items, products => dataLayerPush('ecomOrder', {
    checkout: {
      actionField: { step: 1 },
      products,
    },
  }));
};

export const clickCreateOrderStep2 = ( items, option ) => {
  iterateOverItems(items, products => dataLayerPush('ecomDelivery', {
    checkout: {
      actionField: { step: 2, option },
      products,
    },
  }));
};

export const clickCreateOrderStep3 = ( items ) => {
  iterateOverItems(items, products => dataLayerPush('ecomOrderConfirm', {
    checkout: {
      actionField: { step: 3 },
      products,
    },
  }));
};

export const orderPaymentConfirmed = ( order ) => {
  iterateOverItems(
    order.partInOrder,
    products => dataLayerPush('ecomOrderPaid', {
      purchase: {
        actionField: {
          id:          order.id,
          revenue:     order.summ.toFixed(2).replace(/\./g, ','),
          affiliation: window.location.host,
        },
        products,
      },
    }),
    ( item, i ) => {
      const part = convertPart(item.part, i);
      part.price = item.price;
      return part;
    },
  );
};
