import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import style from './style';

export default function Link(props) {
  const Tag = props.target === '_blank'
    ? 'a'
    : RouterLink;
  return <Tag {...props} className={classnames(props.className, style.link)} />;
}

Link.propTypes = {
  className: PropTypes.string,
  target:    PropTypes.string,
};
