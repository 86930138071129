import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './style';
import * as propTypes from '../../../propTypes';
import formatDate from '../../../utils/formatDate';
import generateBrand from '../../../utils/generateBrand';
import { detailPage } from '../../../utils/links';

import Meta from '../../../components/Meta';
import Link from '../../components/Link';
import Title from '../../components/Title';
import Price from 'src/shared/components/Price';

const renderItem = (item) => {
  const { part } = item;
  const params = [
    { label: 'Артикул:', value: part.barcode },
    { label: 'Ориг. номер:', value: part.kod },
    { label: 'Сост.:', value: part.used ? 'Б/у' : 'Новая' },
    { label: 'Цена:', value: <Price value={item.price} /> },
  ];

  return (
    <div key={part.barcode} className={style.item}>
      <Link className={style.title} to={detailPage(item.part)}>
        {part.partGroup && part.partGroup.title} {' '}
        {part.partName && part.partName.title} {' '}
        {generateBrand(item.part.relatedBrand)}
      </Link>
      <div className={style.params}>
        {params.map(param => (
          <div key={param.label} className={style.param}>
            <div className={style.label}>{param.label}</div>
            <div className={style.value}>{param.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

class OrdersSinglePageContainer extends Component {
  static propTypes = {
    token: PropTypes.string,
    order: propTypes.order,
  };

  render() {
    const { order } = this.props;
    const params = [
      { label: 'Способ получения:', value: order.deliveryType ? order.deliveryType.title : '-' },
      { label: 'Сумма заказа:', value: <Price value={order.summ} /> },
    ];
    const title = `Заказ №${order.id} от ${formatDate(order.created)}`;

    return (
      <div>
        <Meta title={title} />
        <Title>{title}</Title>
        <div className={style.items}>
          {order.partInOrder.map(renderItem)}
        </div>
        <div className={style.params}>
          {params.map(param => (
            <div key={param.label} className={style.param}>
              <div className={style.label}>{param.label}</div>
              <div className={style.value}>{param.value}</div>
            </div>
          ))}
        </div>
        <div className={style.address}>{order.deliveryAddres}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.token,
  order: state.order,
});
export default connect(mapStateToProps)(OrdersSinglePageContainer);
