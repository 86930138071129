import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as propTypes from '../../../propTypes';
import * as API from '../../../utils/API';
import style from './style';
import formatDate from '../../../utils/formatDate';
import * as dataLayer from '../../../utils/dataLayer';

import Meta from '../../../components/Meta';
import Title from '../../components/Title';
import Price from '../../../components/Price';
import { connectAppConfig } from 'src/shared/components/AppConfig';

class PaymentContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    location: propTypes.location,
    query:    PropTypes.shape(),
  };

  static contextTypes = {
    store: PropTypes.shape(),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      status: null,
      order:  null,
      error:  null,
    };
  }

  componentDidMount() {
    this.props.requester.send(API.validatePayment(this.props.query))
      .then(
        (res) => {
          this.setState(res);
          dataLayer.orderPaymentConfirmed(res.order);
        },
        err => this.setState({ status: 'error', error: err.error.error.common[0] }),
      );
  }

  renderSuccess() {
    const { order } = this.state;
    const params = [
      { label: 'Способ получения', value: order.deliveryType ? order.deliveryType.title : '-' },
      { label: 'Место получения', value: order.deliveryAddres },
      { label: 'Сумма заказа', value: <Price value={order.summ} /> },
    ];

    return (
      <div>
        <Meta title="Оформление заказа" />
        <Title>Платеж успешно совершен!</Title>
        <h2 className={style.subheader}>Заказ №{order.id} от {formatDate(order.created)}</h2>
        <div className={style.order_info}>
          {params.map(field => (
            <div key={field.label} className={style.field}>
              <div className={style.label}>{field.label}:</div>
              <div className={style.value}>{field.value}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderError() {
    return (
      <div>
        <Meta title="Оформление заказа" />
        <Title>Во время выполнения платежа произошла ошибка</Title>
        <h2>{this.state.error}</h2>
      </div>
    );
  }

  render() {
    if (!this.state.status) return <div className={style.loading} />;
    if (this.state.status === 'error') return this.renderError();
    return this.renderSuccess();
  }
}

export default connectAppConfig(connect()(PaymentContainer));
